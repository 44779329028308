
body {
  margin: 0;
  font-family: 'Lato', sans-serif;
}
@tailwind base;
@tailwind components;
@tailwind utilities;







